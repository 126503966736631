import { ethers, BigNumber, utils } from "ethers";
import { useEffect, useState } from "react";
import Zmb from "../hardhut/artifacts/contracts/zombieContract.sol/ZombieMint.json";
import Car from "../hardhut/artifacts/contracts/carContract.sol/CarMint.json";
import Coin from "../hardhut/artifacts/contracts/brainCoin.sol/Brains.json";
import one from "./img/1.jpg"
import two from "./img/2.jpg"
import three from "./img/3.jpg"
// car images
import four from "./img/4.png"
import five from "./img/5.png"
import six from "./img/6.jpg"

const zombieContract = "0xaDa17Ce25FE48911C1C6dEdD461f25bE67F94d8F";
const carContract = "0xb74449315543DB720d21EA7be476ac0192F13D83";
const coinContract = "0xaAA791D021AEf802d72742b667300bCE8572f21a";

const provider = new ethers.providers.Web3Provider(window.ethereum);
const signer = provider.getSigner();

const zmbRouter = new ethers.Contract(zombieContract, Zmb.abi, signer); // 
const carRouter = new ethers.Contract(carContract, Car.abi, signer);  
const brainRouter = new ethers.Contract(coinContract, Coin.abi, signer);

function UserInt() {

    // Zombies
    const [userIds, setUserIds] = useState([]);         // Zombies ids
    const [userRarity, setUserRarity] = useState([]);   // Zombies rarities

    // cars
    const [carIds, setCarIds] = useState([]);
    const [carRar, setCarRar] = useState([]);

    useEffect(() => {
        renderImg();
        getIds();
        rednerCar();
        getIdsCar();
    }, [])


    // Car functions
    const payForCar = async () => {                         // pay with brains for car
        const mintCar = await carRouter.payWithBr();
        await mintCar.wait();
    }

    const rednerCar = async () => {                         // Get Car rarity and puts in array "carRar"
        const rar = await carRouter.getRar();
        for(let i = 0; i < rar.length; i++) {
            let x = ethers.BigNumber.from(rar[i]).toNumber();
            setCarRar([...carRar, x]);
        }
    }

    const getIdsCar = async () => {                         // Gets car ids and puts in array "carIds"
        const iddd = await carRouter.getIds();
        for(let i = 0; i < iddd.length; i++){
            let y = ethers.BigNumber.from(iddd[i]).toNumber();
            setCarIds([...carIds, y]);
        }
    }

    carRouter.on(("minted", (id) => {                           // event listener for finished "king minting"
        rednerCar();
        getIdsCar();
    }));


    // zombies functions -------------------------------

    // const payToMint = async () => {
    //     const pay = await zmbRouter.payForNft( { value: ethers.utils.parseEther("0.01") } ); // Triggers event -- so oracle can purchase.
    //     await pay.wait();
    //     console.log(pay);
    // }

    const payToMint = async () => {                             // Pay with brains to min zombie nft
        const pay = await zmbRouter.payWithBr();
        await pay.wait();
        console.log(pay);
    }

    const renderImg = async () => {                             // Get rarity of zombies and puts in array "userRarity"
        const rar = await zmbRouter.getStrength();
        for(let i = 0; i < rar.length; i++) {
            let x = ethers.BigNumber.from(rar[i]).toNumber();
            setUserRarity([...userRarity, x]);
        }
    }

    const getIds = async () => {                                // gets ids of zombies ad puts in array "userIds"
        const idd = await zmbRouter.getIds();
        for(let i = 0; i < idd.length; i++){
            let y = ethers.BigNumber.from(idd[i]).toNumber();
            setUserIds([...userIds, y]);
        }
    }

    zmbRouter.on("minted", (id) => {                            // event listener for finished "king minting"
        renderImg();
        getIds();
    })


    // brains -coin 
    const [allowance, setAllowance] = useState([]); // Still dont know how to pass hex to num ...
    const approveZombie = async () => {
        const [account] = await window.ethereum.request({ method: "eth_requestAccounts", });
        let x = await brainRouter.balanceOf(account);
        const zmbApprove = await brainRouter.approve(zombieContract, x);
        await zmbApprove.wait();
    }

    const approveCar = async () => {
        const [account] = await window.ethereum.request({ method: "eth_requestAccounts", });
        let x = await brainRouter.balanceOf(account);
        const carApprove = await brainRouter.approve(carContract, x);
        await carApprove.wait();
    }

    return (
        <>
        <h3> Mint a random Zombie for 2500 Brains.</h3>
        <button onClick={payToMint}>Mint Zombie!</button>
        <button onClick={approveZombie}>Approve Zombie</button>
        <br></br>
        <h3> Your Zombies !</h3>
        <h3> Your Rarities: {userRarity} Your Ids: {userIds}</h3>
            {userRarity.map((item, index) => {
                if(item == 1) {
                    return (
                        <img key={index} src={one}/>
                    )
                } else if(item == 2) {
                    return (
                        <img key={index} src={two}/>
                    )
                } else if(item == 3) {
                    return (
                        <img key={index} src={three}/>
                    )
                }
            })}
            <h3> Mint a random Car for 4500 Brains.</h3>
            <button onClick={payForCar}>Mint Car!</button>
            <button onClick={approveCar}>Approve Car</button>
            <h3> Your Cars! </h3>
            <h3> Your Rarities: {carRar} Your Ids: {carIds}</h3>
            {carRar.map((item, index) => {
                if(item == 1) {
                    return (
                        <img key={index} src={five}/>
                    )
                } else if(item == 2) {
                    return (
                        <img key={index} src={five}/>
                    )
                } else if(item == 3) {
                    return (
                        <img key={index} src={six}/>
                    )
                } else {
                    return(
                        <img key={index} src={four}/>
                    )
                }
            })}
        </>
    )

}

export default UserInt;